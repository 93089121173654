<template>
    <b-card :header="$t('prod_onderzoek', { ns: 'production' })">
        <div v-if="hasBarcode">
            <b-row>
                <b-col>
                    <div id="targetDiv" class="form-inline">
                        <div class="mx-0 px-0 my-0 py-0" style="float: left">
                            <barcode
                                v-bind:value="barcodeValue"
                                class="mx-0 px-0 my-0 py-0"
                                width="2"
                            >
                            </barcode>
                        </div>
                        <div class="mx-1 px-1 my-1 py-1" style="float: left">
                            <h3>{{ objectNaam }}</h3>
                        </div>
                    </div>
                </b-col>
                <b-col cols="2">
                    <b-button
                        @click="printBarcode()"
                        class="bgc_aux_unimportant"
                        ><font-awesome-icon icon="print" size="1x" />
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <card-investigation-for-template
                        v-bind:templateObjectID="templateObjectID"
                        v-bind:entityObjectID="entityObjectID"
                        v-bind:forceRefresh="forceRefresh"
                        @change="setChanged"
                    />
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>
<script>
import CardInvestigationForTemplate from '../analytics/CardInvestigationForTemplate.vue';
import VueBarcode from 'vue-barcode';
import { EntityObject } from '@/models/Anayltics';
export default {
    name: 'AnalyticsDisplay',
    data() {
        return {
            nodisplay: this.$t('com_nodisplaybarcode', { ns: 'common' })
        };
    },
    model: {
        prop: 'eo',
        event: 'input'
    },
    props: {
        eo: {
            type: EntityObject,
            required: true
        },
        forceRefresh: {
            type: Boolean,
            default: false
        },
        landscape: {
            type: Boolean,
            default: false
        }
    },
    components: {
        barcode: VueBarcode,
        CardInvestigationForTemplate
    },
    watch: {},
    computed: {
        barcodeValue() {
            // barcode komt uit database of van server
            const value = this.hasBarcode ? this.eo.barcode : '--';
            return value;
        },
        hasBarcode() {
            return this.eo != undefined;
        },
        templateObjectID() {
            const value = this.hasBarcode ? this.eo.templateID : -1;
            return value;
        },
        entityObjectID() {
            const value = this.hasBarcode ? this.eo.id : -1;
            return value;
        },
        objectNaam() {
            // barcode komt uit database of van server
            const value = this.hasBarcode ? this.eo.naam : '--';
            return value;
        }
    },
    methods: {
        setChanged() {
            this.$emit('change', true);
        },
        printBarcode() {
            if (this.landscape) {
                this.$htmlToPaper('targetDiv', {
                    styles: ['/css/bootstrap.min.css', '/css/landscape.css']
                });
            } else {
                this.$htmlToPaper('targetDiv', {
                    styles: ['/css/bootstrap.min.css', '/css/portrait.css']
                });
            }
        } //,
        // printBarcodeOld() {
        //     const mywindow = window.open('', 'PRINT', 'height=400,width=600');

        //     mywindow.document.write(
        //         '<html><head><title>' + document.title + '</title>'
        //     );
        //     // mywindow.document.write(
        //     //     '<link rel="stylesheet" href="main.css" type="text/css" />'
        //     // );
        //     mywindow.document.write('</head><body>');
        //     mywindow.document.write(
        //         document.getElementById('targetDiv').innerHTML
        //     );
        //     mywindow.document.write('</body></html>');

        //     mywindow.document.close(); // necessary for IE >= 10
        //     mywindow.focus(); // necessary for IE >= 10*/

        //     mywindow.print();
        //     mywindow.close();
        //     // var element = document.getElementById('targetDiv');
        //     // element.classList.add('print-area');
        //     // window.print();
        //     return true;
        // } 
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
