<template>
    <b-card
        no-body
        :header="$t('an_standaardx', {
                    ns: 'analytics',
                    val: $t('an_metingen', {
                        ns: 'analytics'
                    }).toLowerCase()
                })"
    >
        <div>
            <b-card>
                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                v-if="checkRight('AnalyticsMeasurementCreate')"
                                size="sm"
                                @click="createMaesurement(row.item)"
                                class="bgc_aux_normal"
                                ><font-awesome-icon icon="edit" size="1x" />
                            </b-button>
                        </div>
                    </template>
                </b-table>
            </b-card>
        </div>
        <modal-measurement
            v-if="showMM"
            v-model="showMM"
            v-bind:idExecutor="idEX"
            v-bind:idIQ="idIQ"
            v-bind:idEntityObject="idEO"
            v-bind:inItem="measurement"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Permission } from '../../models/Permissions';
import ModalMeasurement from './ModalMeasurement.vue';
import { loadList } from './utils';

export default {
    name: 'CardInvestigationForTemplate',
    data() {
        return {
            flexList: [],
            idEO: -1,
            idEX: -1,
            idIQ: -1,
            measurement: null,
            showMM: false
        };
    },
    components: {
        ModalMeasurement
    },
    props: {
        templateObjectID: {
            type: Number,
            default: null
        },
        entityObjectID: {
            type: Number,
            default: null
        },
        forcerefresh: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('an_onderzoek', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'uitleg',
                    label: this.$t('an_uitleg', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            return this.flexList;
        }
    },
    methods: {
        createMaesurement(item) {
            if (item == undefined) return;
            this.idEO = this.entityObjectID;
            this.idIQ = item.id;
            this.showMM = !this.showMM;
        },
        reset() {
            this.showMM = false;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async load() {
            const lijst = await loadList(
                'analytics/getTemplateLijst',
                'analytics/loadTemplateLijst',
                null,
                this.forcerefresh,
                this.$t
            );
            this.flexList = lijst.filter((c) => {
                return c.templateid == this.templateObjectID;
            });
        }
    },
    mounted() {
        this.load();
    },
    async created() {}
};
</script>

