<template>
    <b-modal
        id="modal-measurement"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-card>
                <b-tabs card>
                    <b-tab
                        :title="$t('an_meting', { ns: 'analytics' })"
                        v-bind:key="1"
                        active
                    >
                        <b-card-text>
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <entity-object-selector
                                        v-model="meetplekID"
                                        :editAllowed="editAllowed"
                                        @input="setChangedEO"
                                    ></entity-object-selector>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_uitvoerder', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.uitvoerderID"
                                        v-bind:combotype="lctypeExecutor"
                                        :disabled="!editAllowed"
                                        :showfilter="true"
                                        @input="setChangedExecutor"
                                    ></lookup-combo>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_sample', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.monsterID"
                                        v-bind:combotype="lctypeSamples"
                                        :disabled="!editAllowed"
                                        :showfilter="true"
                                        @input="setChangedSample"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col sm="3">
                                    <label for="dateOnderzoek"
                                        >{{
                                            $t('an_meetdatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateOnderzoek"
                                        v-model="datumOnderzoek"
                                        v-bind:editAllowed="editAllowed"
                                        v-bind:startNull="false"
                                        @change="setDatumOnderzoek"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="dateBoeking"
                                        >{{
                                            $t('an_plandatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateBoeking"
                                        v-model="datumBoeking"
                                        v-bind:editAllowed="editAllowed"
                                        v-bind:startNull="true"
                                        @change="setDatumBoeking"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_meting', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <measurement-combo
                                        v-model="locMaesureObject"
                                        v-bind:editAllowed="editAllowed"
                                        v-bind:filterIQs="filterIQs"
                                        v-bind:IQ="lokaalIQ"
                                        @change="setChanged"
                                        @input="setMaesurement"
                                    ></measurement-combo>
                                </b-col>
                                <b-form-textarea
                                    v-model="localItem.opmerking"
                                    :placeholder="
                                        $t('com_opmerking', { ns: 'common' })
                                    "
                                    rows="3"
                                    max-rows="6"
                                    @change="setChanged"
                                ></b-form-textarea>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <b-tab
                        v-if="localItem.id > 0"
                        :title="$t('com_bijlagen', { ns: 'common' })"
                        v-bind:key="3"
                        @click="loadAttachments()"
                        lazy
                    >
                        <b-card-text>
                            <file-upload
                                :attID="localItem.id"
                                :attType="attachmentType"
                                @input="loadAttachments"
                                @queue="setQueuedAmount"
                            />
                            <b-table
                                :items="attachmentLijst"
                                :fields="visibleFields"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button
                                        size="sm"
                                        @click="showAttachment(row.item)"
                                        class="bgc_aux_normal"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button>
                                    <b-button
                                        size="sm"
                                        @click="verwijderAttachment(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </template>

                                <template v-slot:row-details> </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import { MaesureObject, Maesurement } from '../../models/Anayltics';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from './utils';
import LookupCombo from './LookupCombo.vue';
import EntityObjectSelector from './EntityObjectSelector.vue';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import MeasurementCombo from './MeasurementCombo.vue';
import { AttachmentInfo } from '@/models/PlusProduction';
import { FileUpload } from '../components';
export default {
    name: 'ModalMeasurement',
    data: function () {
        return {
            lctypeExecutor: window.constants.LCA_EXECUTOR,
            lctypeEO: window.constants.LCA_ENTITYOBJECT,
            lctypeSamples: window.constants.LCA_SAMPLES,
            attachmentType: window.constants.ATT_MEASUREMENT,
            queuedAmount: 0,
            attachmentLijst: [], // as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            showDismissibleAlert: false,
            forcerefresh: false,
            alertText: '',
            isChanged: false,
            naam: null,
            meetplekID: -1,
            locItem: null,
            locMaesureObject: null,
            returnObject: null,
            editAllowed: true,
            datumBoeking: null,
            datumOnderzoek: null,
            aangeraakt: 1,
            filterIQids: '',
            buitenNormString: this.$t('an_warnbuitennormwaarde', {
                ns: 'analytics'
            }),
            productieNormString: this.$t('an_reginprod', { ns: 'analytics' }),
            productieNormStringRemark:
                this.$t('an_reginprod', { ns: 'analytics' }) +
                ' ' +
                this.$t('com_opmerking', { ns: 'common' }) +
                ' : {1}'
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: Maesurement,
            required: false
        },
        isNew: {
            type: Boolean,
            default: true
        },
        barcodeExecutor: {
            type: String,
            required: false
        },
        barcodeEntityObject: {
            type: String,
            required: false
        },
        barcodeSample: {
            type: String,
            required: false
        },
        idExecutor: {
            type: Number,
            required: false
        },
        idSample: {
            type: Number,
            required: false
        },
        idEntityObject: {
            type: Number,
            required: false
        },
        idIQ: {
            type: Number,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        MeasurementCombo,
        EntityObjectSelector,
        FileUpload,
        AnalyticsDateTime
    },
    watch: {
        barcodeExecutor() {
            this.checkBarcodeEX(this.barcodeExecutor);
        },
        barcodeEntityObject() {
            this.checkBarcodeEO(this.barcodeEntityObject);
        },
        barcodeSample() {
            this.checkBarcodeSample(this.barcodeSample);
        }
    },
    computed: {
        formtitle() {
            const low = this.$t('an_meting', { ns: 'analytics' }).toLowerCase();
            if (this.locItem == undefined)
                return this.$t('com_newx', {
                    ns: 'common',
                    val: low
                });

            return this.locItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: low
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.locItem.onderzoeksgrootheidNaam ?? low
                  });
        },
        localItem() {
            const a = this.aangeraakt;
            return this.locItem;
        },
        magEdit() {
            return !(this.locItem.typenaam && this.locItem.veldid);
        },
        filterIQs() {
            return this.filterIQids;
        },
        lokaalIQ() {
            const a = this.aangeraakt;
            return this.locMaesureObject.grootheidid;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        printBarcode() {
            this.$htmlToPaper('targetDiv');
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setDatumOnderzoek(value) {
            this.datumOnderzoek = value;
            this.setChanged();
        },
        setDatumBoeking(value) {
            this.datumBoeking = value;
            this.setChanged();
        },
        setChangedEO(value) {
            this.localItem.meetplekID = value;
            this.setChanged();
        },
        async setChangedExecutor(value) {
            // zonder () krijg je de nieuwe waarde
            const nieuweEx = value;
            const lijst = await loadList(
                'analytics/getExecutorLijst',
                'analytics/loadExecutorLijst',
                null,
                this.forcerefresh,
                this.$t
            );
            const exec = lijst.find((x) => x.id == nieuweEx);
            if (exec) {
                this.filterIQids = exec.naam;
            }

            this.setChanged();
        },
        async setChangedSample(value) {
            // aanroep zonder () krijg je de nieuwe waarde
            if (value) {
                const sample = await loadItem(
                    'analytics/loadSample',
                    { sampleID: value },
                    this.$t
                );
                // check met de gegeven bij eo
                if (this.localItem.meetplekID > 0 && sample) {
                    if (this.meetplekID != sample.meetplekID) {
                        const bericht = this.$t('an_deltaeosample', {
                            ns: 'analytics'
                        });
                        const gadoor = await this.$root.showConfirmationBox(
                            bericht
                        );
                        if (gadoor) {
                            this.localItem.meetplekID = sample.meetplekID;
                            this.meetplekID = sample.meetplekID;
                        } else {
                            this.localItem.sampleID = undefined;
                        }
                    }
                }
            } else {
                this.localItem.sampleID = undefined;
            }
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        setMaesurement(item) {
            if (item) {
                this.localItem.waarde = item.hoeveelheid;
                this.localItem.onderzoeksgrootheidID = item.grootheidid;
                this.localItem.eenheidID = item.eenheidid;
            }
        },
        async checkFormValidity() {
            let invoerOK = true;
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) {
                this.showAlert(invalidBasis);
                return false;
            }
            const norm = await loadItem(
                'analytics/loadNorm',
                {
                    entityObjectID: this.localItem.meetplekID,
                    invQuantityID: this.localItem.onderzoeksgrootheidID
                },
                this.$t
            );
            if (norm) {
                if (norm.minwaarde || norm.maxwaarde) {
                    // als het temperatuur is dan mogelijk fahrenheit  berekenen
                    if (this.localItem.eenheidID == window.constants.ADATA_QUN_FAHRENHEIT) {
                        norm.minwaarde = Helper.Round(Helper.CelsiusToFahrenheit(norm.minwaarde),2);
                        norm.maxwaarde = Helper.Round(Helper.CelsiusToFahrenheit(norm.maxwaarde),2);
                        norm.normwaarde = Helper.Round(Helper.CelsiusToFahrenheit(norm.normwaarde),2);
                    }
                    this.localItem.minlimiet = norm.minwaarde;
                    this.localItem.maxlimiet = norm.maxwaarde;
                    if (norm.basisProductie) {
                        if (this.localItem.opmerking) {
                            const opmerking = Helper.stringformat(
                                this.productieNormStringRemark,
                                norm.normwaarde,
                                this.localItem.opmerking
                            );
                            this.localItem.opmerking = opmerking;
                        } else {
                            const opmerking = Helper.stringformat(
                                this.productieNormString,
                                norm.normwaarde
                            );
                            this.localItem.opmerking = opmerking;
                        }
                    }
                    this.localItem.validated = true;
                    let goed = true;
                    if (norm.minwaarde) {
                        if (norm.maxwaarde) {
                            goed =
                                this.localItem.waarde > norm.minwaarde &&
                                this.localItem.waarde < norm.maxwaarde;
                        } else {
                            goed = this.localItem.waarde > norm.minwaarde;
                        }
                    } else {
                        if (norm.maxwaarde) {
                            goed = this.localItem.waarde < norm.maxwaarde;
                        }
                    }
                    if (!goed) {
                        const melding = Helper.stringformat(
                            this.buitenNormString,
                            this.localItem.waarde,
                            norm.minwaarde,
                            norm.maxwaarde
                        );
                        invoerOK = await this.$root.showConfirmationBox(
                            melding
                        );
                    }
                }
            }
            const valid =
                this.locItem != undefined && !invalidBasis && invoerOK;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            this.locItem.meettijdstip = this.datumOnderzoek;
            this.locItem.boektijdstip = this.datumBoeking;
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!(await this.checkFormValidity())) {
                    return;
                }
                this.returnObject = await this.saveThisMaesurement();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-measurement');
            });
        },
        async saveThisMaesurement() {
            const addObject = this.locItem.SetForAdd();
            return loadItem('analytics/saveMaesurement', addObject, this.$t);
        },
        async checkBarcodeEO(value) {
            if (this.locItem.meetplekID == undefined) {
                const lijst = await loadList(
                    'analytics/getEntityObjectLijst',
                    'analytics/loadEntityObjectByTypename',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => x.barcode == value);
                if (o) {
                    this.locItem.meetplekID = o.id;
                    // check of hij een template heeft, daarmee preset
                    if (o.templateID > 0) {
                        const complijst = await loadList(
                            'analytics/getTemplateLijst',
                            'analytics/loadTemplateLijst',
                            null,
                            this.forcerefresh,
                            this.$t
                        );
                        const template = complijst.find(
                            (x) => x.templateid == o.templateID
                        );
                        if (template) {
                            this.localItem.uitvoerderID = template.uitvoerderid;
                            this.locMaesureObject.grootheidid = template.id;
                        }
                        this.aangeraakt += 1;
                    }
                }
            }
            this.meetplekID = this.locItem.meetplekID;
        },
        async checkBarcodeSample(value) {
            if (this.locItem.monsterID == undefined) {
                const nu = new Date();
                const toen = DateHelper.SubstractYear(nu);
                const selectionData = {
                    from: DateHelper.localDateToUTC(toen),
                    until: DateHelper.localDateToUTC(nu)
                };
                const lijst = await loadList(
                    'analytics/getMonsterLijst',
                    'analytics/loadSampleLijst',
                    selectionData,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => x.barcode == value);
                if (o) {
                    this.locItem.monsterID = o.id;
                    this.locItem.meetplekID = o.meetplekID;
                    this.aangeraakt += 1;
                }
            }
        },
        async checkBarcodeEX(value) {
            if (this.locItem.uitvoerderID == undefined) {
                const lijst = await loadList(
                    'analytics/getExecutorLijst',
                    'analytics/loadExecutorLijst',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => x.barcode == value);
                if (o) {
                    this.locItem.uitvoerderID = o.id;
                    this.aangeraakt += 1;
                }
            }
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'analytics/loadAttachmentInfo',
                {
                    data: {
                        id: this.locItem.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'analytics/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'analytics/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    async mounted() {
        if (this.barcodeExecutor!=undefined) {
            await this.checkBarcodeEX(this.barcodeExecutor);
        }
        if (this.barcodeEntityObject!=undefined) {
            await this.checkBarcodeEO(this.barcodeEntityObject);
        }
        if (this.barcodeSample!=undefined) {
            await this.checkBarcodeSample(this.barcodeSample);
        }
    },
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.datumOnderzoek = this.locItem.meettijdstip;
            this.datumBoeking = this.locItem.boektijdstip;
            this.locMaesureObject = new MaesureObject();
            this.locMaesureObject.hoeveelheid = this.locItem.waarde;
            this.meetplekID = this.locItem.meetplekID;
            this.locMaesureObject.grootheidid =
                this.locItem.onderzoeksgrootheidID;
            this.locMaesureObject.eenheidid = this.locItem.eenheidID;
            this.naam = this.locItem.naam;
            if (this.isNew) {
                this.locItem.clearID();
                this.datumOnderzoek = new Date();
            }
        } else {
            this.datumOnderzoek = new Date();
            this.datumBoeking = new Date();
            this.locItem = new Maesurement();
            this.locItem.onderzoeksgrootheidID = this.idIQ;
            this.locItem.uitvoerderID = this.idExecutor;
            this.locItem.meetplekID = this.idEntityObject;
            this.meetplekID = this.locItem.meetplekID;
            this.locItem.monsterID = this.idSample;
            this.locMaesureObject = new MaesureObject();
            if (this.idIQ) {
                this.locMaesureObject.grootheidid = this.idIQ;
            }
        }
    }
};
</script>

<style scoped></style>


