<template>
    <b-card no-body :header="$t('com_locations', { ns: 'common' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="readonly"
                            @change="setChangedNaam"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        />
                    </b-col>

                    <b-col sm="3">
                        <label for="input-locatieType"
                            >{{ $t('prod_type', { ns: 'production' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-select
                            v-model="locationType"
                            :options="flexList"
                            @change="setType"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-card
                    no-body
                    :header="$t('prod_siloinfo', { ns: 'production' })"
                    v-if="show"
                >
                    <b-col sm="3">
                        <label for="input_tank"
                            >{{ $t('prod_volume', { ns: 'production' }) }}:
                        </label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input_tank"
                            :placeholder="
                                $t('prod_volume', { ns: 'production' })
                            "
                            v-model="volume"
                            @change="setVolume"
                            :disabled="readonly"
                        ></b-form-input>
                    </b-col>
                </b-card>
                <analytics-display
                    v-if="isVersion30 && eo!=undefined"
                    :eo="eo"
                    :forceRefresh="forceRefresh"
                    :landscape="landscape"
                />
            </b-card>
        </div>
        <modal-entity-object
            v-if="detailsVisible"
            :inItem="eo"
            v-model="detailsVisible"
            v-bind:typeName="theLocation.tableName"
            v-bind:itemName="theLocation.naam"
            v-bind:keyNummeric="theLocation.id"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Location } from '../../models/MasterData';
import { Permission } from '../../models/Permissions';
import { loadItem, loadList } from '@/models/DataHelper';
import ModalEntityObject from '../analytics/ModalEntityObject.vue';
import AnalyticsDisplay from './AnalyticsDisplay.vue';

export default {
    name: 'CardLocation',
    data() {
        return {
            heightBC: 50,
            widthBC: 2,
            locatieType: false,
            locationType: null,
            volume: null,
            naam: '',
            detailsVisible: false,
            flexList: [],
            forceRefresh: false,
            landscape: true,
            eo: null
        };
    },
    components: {
        AnalyticsDisplay,
        ModalEntityObject
    },
    model: {
        prop: 'theLocation',
        event: 'changed'
    },
    props: {
        theLocation: {
            type: Location,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        readonly() {
            return !this.editAllowed;
        },
        show() {
            return this.locatieType;
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        }
    },
    methods: {
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        setInput: function () {
            if (!this.locatieType) this.theLocation.loctype = null;
            this.setChanged();
        },
        setType: function () {
            this.theLocation.loctype = this.locationType;
            this.setChanged();
        },
        setChangedNaam: function () {
            this.theLocation.naam = this.naam;
            this.setChanged();
        },
        setVolume: function () {
            this.theLocation.volume = this.volume;
            this.setChanged();
        },
        setChanged: function () {
            this.$emit('validComponent', this.theLocation.naam != undefined);
            this.$emit('change', true);
        },
        reset(returnObject) {
            this.detailsVisible = false;
            if (returnObject != undefined) this.eo = returnObject;
        }
    },
    mounted() {
        this.locatieType = this.theLocation.loctype != undefined;
        this.locationType = this.theLocation.loctype;
        this.naam = this.theLocation.naam;
        this.volume = this.theLocation.volume;
    },
    async created() {
        this.flexList = await loadList(
            'masterdata/getStockLocationTypesLijst',
            'masterdata/loadStockLocationTypes',
            null,
            true,
            this.$t
        );
        if (this.isVersion30) {
            await loadItem(
                'analytics/loadEntityObjectByTypename',
                {
                    typename: this.theLocation.tableName
                },
                this.$t
            );
            this.eo = this.$store.getters['analytics/getEntityObjectByTypeID'](
                this.theLocation.id
            );
        }
    }
};
</script>
<style scoped>
</style>

