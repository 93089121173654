import Api from '../../../api';
import store from '../../../store';

const fetchOsts = async (data) => {
    if (data == undefined) {
        return fetchInfo('/MasterData/GetAllOsts', data);
    } else {
        return fetchInfo('/MasterData/GetOsts', data);
    }
};
const fetchOst = async (data) => {
    return fetchInfo('/MasterData/GetOst', data);
};

const addOst = async (data) => {
    return Api(store).put('/MasterData/StoreOst', data);
};

const deleteOst = async (data) => {
    return deleteInfo('/MasterData/DeleteOst', data);
};

const fetchStockLocationTypes = async (data) => {
    return fetchInfo('/MasterData/GetStockLocationTypes', data);
};

const fetchLocations = async (data) => {
    return fetchInfo('/MasterData/GetStockLocations', data);
};

const fetchLocation = async (data) => {
    return fetchInfo('/MasterData/GetStockLocation', data);
};

const addLocation = async (data) => {
    return Api(store).put('/MasterData/StoreStockLocation', data);
};

const deleteLocation = async (data) => {
    return deleteInfo('/MasterData/DeleteStockLocation', data);
};

const fetchCompanies = async (data) => {
    return fetchInfo('/Company/GetCompanyInfo', data);
};
// companyid
const fetchCompany = async (data) => {
    return fetchInfo('/Company/GetCompany', data);
};

const addCompany = async (data) => {
    return Api(store).put('/Company/StoreCompany', data);
};

const deleteCompany = async (data) => {
    return deleteInfo('/Company/DeleteCompany', data);
};

const fetchShapes = async (data) => {
    return fetchInfo('/MasterData/GetShapes', data);
};

const fetchShape = async (data) => {
    return fetchInfo('/MasterData/GetShape', data);
};

const addShape = async (data) => {
    return Api(store).put('/MasterData/StoreShape', data);
};

const deleteShape = async (data) => {
    return deleteInfo('/MasterData/DeleteShape', data);
};

const fetchEquipments = async (data) => {
    return fetchInfo('/MasterData/GetAllEquipment', data);
};

const fetchEquipment = async (data) => {
    return fetchInfo('/MasterData/GetEquipment', data);
};

const addEquipment = async (data) => {
    return Api(store).put('/MasterData/StoreEquipment', data);
};

const deleteEquipment = async (data) => {
    return deleteInfo('/MasterData/DeleteEquipment', data);
};

const fetchVersionInfo = async (data) => {
    return fetchInfo('/MasterData/GetVersion', data);
};

const fetchGebruikInfo = async (data) => {
    return fetchInfo('/Reporting/AttachmentReport', data);
};

const fetchDeployedPackageInfo = async (data) => {
    return fetchInfo('/MasterData/GetDeployedPackage', data);
};

const fetchApplicationConstants = async (data) => {
    return fetchInfo('/MasterData/GetApplicationConstants', data);
};

const fetchMessages = async (data) => {
    return fetchInfo('/Reporting/GetMessages', data);
};


// string constantName, bool value
const storeApplicationConstant = async (data) => {
    return putInfo('/MasterData/StoreApplicationConstant', data);
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
};
const putInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
};
export default {
    fetchOsts,
    fetchOst,
    addOst,
    deleteOst,
    fetchShapes,
    fetchShape,
    addShape,
    deleteShape,
    fetchLocations,
    fetchLocation,
    addLocation,
    deleteLocation,
    fetchCompanies,
    fetchCompany,
    addCompany,
    deleteCompany,
    fetchEquipments,
    fetchEquipment,
    addEquipment,
    deleteEquipment,
    fetchVersionInfo,
    fetchGebruikInfo,
    fetchDeployedPackageInfo,
    fetchApplicationConstants,
    fetchMessages,
    storeApplicationConstant,
    // enums
    fetchStockLocationTypes
};
